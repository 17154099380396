@import 'webresources-sources/scss/imports';

.image-component {
  .caption {
    color: $color-gray-600;

    .bg-light &,
    .bg-dark & {
      color: $color-white;
    }
  }

  .copyright {
    color: $color-gray-500;

    .bg-light &,
    .bg-dark & {
      color: $color-darkgreen-200;
    }
  }
}
