/* bootstrap overwrite */
.image-component .caption {
  color: #657785;
}
.bg-light .image-component .caption, .bg-dark .image-component .caption {
  color: #fff;
}
.image-component .copyright {
  color: #c9d1e3;
}
.bg-light .image-component .copyright, .bg-dark .image-component .copyright {
  color: #bcf0e8;
}